import React, {useEffect, useState} from "react"

const AudioTranscriptTest = (props) => {

	const [expandAudio, setExpandAudio] = useState(false);
	const [ssMode, setSSMode] = useState(false);

	const toggleExpand =(e)=>{
		setExpandAudio(!expandAudio);
		e.preventDefault();
	}

	useEffect(()=>{
		let url_string = (window.location.href).toLowerCase();
		let url = new URL(url_string);
		let ss = url.searchParams.get("screenshot");
		if(ss === "true"){
		  setSSMode(true);
		  setExpandAudio(true);
		}
	  },[]);

  return (
	  <div className="audio-transcript-wrapper">
		<button onClick={(e)=>toggleExpand(e)} className="audio-transcript__expand">
		  {!expandAudio &&
			<>
			  Expand Audio Transcript
			</>
		  }
		  {expandAudio &&
			<>
			  Collapse Audio Transcript
			</>
		  }
		</button>
		<div className={expandAudio? `audio-transcript isOpen styled-scrollbars ` + ssMode : `audio-transcript styled-scrollbars ` + ssMode}>
			{props.slug === "calabrese" &&
				<>
				<p><strong>Understanding PMR Pathophysiology: An Expert&#39;s Perspective</strong></p>

				<p><strong>ReachMD Announcer:</strong><br />
				Welcome to ReachMD. This medical industry feature, titled &ldquo;Understanding PMR Pathophysiology: An Expert&#39;s Perspective&rdquo; is sponsored by Sanofi Genzyme and Regeneron Pharmaceuticals. This program is intended for US healthcare professionals.<br />
				Here&rsquo;s Dr Leonard Calabrese.</p>

				<p><strong>Dr Calabrese:</strong><br />
				I&rsquo;m Dr Len Calabrese. I&rsquo;m a professor of medicine at the Cleveland Clinic Lerner College of Medicine.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				What are the patterns of inflammation commonly seen in patients with PMR?</p>

				<p><strong>Dr Calabrese:</strong><br />
				Polymyalgia rheumatica is one of the most common inflammatory syndromes seen in patients after the age of 50. It&rsquo;s characterized by the acute or subacute onset of pain and stiffness in the neck and shoulders and hip girdle and low back.</p>

				<p>This is also exquisitely sensitive to glucocorticoids which are highly effective therapy, but unfortunately are required for prolonged periods of time and mount attendant toxicity.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				Which immune pathways have been linked to PMR disease activity?</p>

				<p><strong>Dr Calabrese:</strong><br />
				The immunopathogenesis of polymyalgia rheumatica is incompletely understood. But there&rsquo;s robust evidence that there are perturbations of both innate and adaptive immune responses. There is overexpression of an activation of certain T-cell subsets and at the same time it is mediated primarily by cytokines.</p>

				<p>Many of the clinical expressions of PMR such as pain, fatigue, early morning stiffness are believed to be mediated by inflammatory cytokines. These cytokines generate the acute phase response, elevated sed rate and CRP and are also responsible for pain, early morning stiffness. Among these cytokines that have been incriminated in polymyalgia rheumatica include IL-6, IL-1, TNF, IL-17 and others.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				What makes the pathophysiology of PMR different from other rheumatic diseases?</p>

				<p><strong>Dr Calabrese:</strong><br />
				First of all, it is among if not the most common inflammatory disease associated with aging. It virtually doesn&rsquo;t occur before the age of 50 and becomes increasingly common after that. We believe that this is linked in some way to immunosenescence of the immune system.</p>

				<p>There&rsquo;s a strong relationship between polymyalgia rheumatica and GCA. Epidemiologically they are tightly linked because many patients may have both conditions. Yet at the same time many patients have either one or the other. We know that cytokines are drivers of both conditions, and that IL-6 plays a prominent role in both of these disorders. Together the links are very strong, but we don&rsquo;t understand at this time why they are separate and why they overlap.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				What evidence exists for the role of IL-6 in PMR pathophysiology?</p>

				<p><strong>Dr Calabrese:</strong><br />
				There&rsquo;s a robust body of data supporting the role of IL-6 in the immunopathogenesis of polymyalgia rheumatica. First and foremost is its strong association with giant cell arteritis where we know that IL-6 is an important inflammatory cytokine. In polymyalgia rheumatica IL-6 can drive pain, early morning stiffness, is associated with intercurrent mood disorders and fatigability. Experimental evidence has demonstrated that IL-6 increases as disease activity increases in polymyalgia rheumatica and finally, and most recently, IL-6 has been found by in situ immunohistochemistry within the inflamed tissues of patients with PMR. Collectively, IL-6 is a very prominent part of the pathogenesis of polymyalgia rheumatica.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				How does IL-6 contribute to disease progression?</p>

				<p><strong>Dr Calabrese:</strong><br />
				The role of IL-6 in polymyalgia rheumatica has broad implications. Not only is it a driver of the acute phase response, but it&rsquo;s responsible for many of the attendant symptoms. We know that it is directly related to pain where IL-6 has nociceptive properties. We also know that IL-6 is a highly efficient biomarker for intercurrent mood disorders, which are often seen in patients with chronic inflammatory diseases. IL-6 is also probably the most reproducible circadian cytokine during sleep and disturbances of sleep and painful and inflammatory conditions cause intrusion of IL-6 in the early morning hours, contributing to early morning stiffness. Finally, IL-6 has been directly linked to many fatigue-like states which are very common in polymyalgia rheumatica.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				For More Information on PMR, Visit www.PMRandIL6.com</p>

				<p>This program was sponsored by Sanofi Genzyme and Regeneron Pharmaceuticals. If you missed any part of this discussion, visit ReachMD.com/industry-feature. This is ReachMD. Be Part of the Knowledge.</p>

				<p><strong>References</strong></p>

				<ul>
					<li>Bartoloni E, Pucci G, Alunno A, Gerli R, Shchillaci G. Chapter 9: Polymyalgia Rheumatica. In: Nussinovitch U, ed. The Heart in Rheumatic, Autoimmune and Inflammatory Diseases. 1st edition. Elsevier; 2017:213-231.</li>
					<li>Bauer ME, Teixeira AL. Neuroinflammation in mood disorders: role of regulatory immune cells. Neuroimmunomodulation. 2021;28(3):99-107. doi: 10.1159/0000515594</li>
					<li>Camellino D, Soldano S, Cutolo M, Cimmino MA. Dissecting the inflammatory response in polymyalgia rheumatica: the relative role of IL-6 and its inhibition. Rheumatology Int. 2018;(38):1699-1704. doi:10.1007/s00296-018-4086-1</li>
					<li>Carvajal-Alegria G, Boukhlal S, Divi Cornec D, Valé rie Devauchelle-Pensec V. The pathophysiology of polymyalgia rheumatica, small pieces of a big puzzle. Autoimmun Rev. 2020;19(11):102670. doi:10.1016/j.autrev.2020.102670</li>
					<li>Crowson CS, Matteson EL, Myasoedova E, Michet CJ, Ernste FC, Warrington KJ, et al. The Lifetime Risk of Adult-Onset Rheumatoid Arthritis and Other Inflammatory Autoimmune Rheumatic Diseases. Arthritis Rheum. 2011;63(3)633-639. doi:10.1002/art.30155.</li>
					<li>De Jongh RF, Vissers KC, Meert TF, Booij LHDJ, De Deyne CS, Heylen RJ. The role of interleukin-6 in nociception and pain. Anesth Analg. 2003;96(4):1096-1103. doi:10.1213/01.ANE.0000055362.56604.78</li>
					<li>Dasgupta B, Panayi GS. Interleukin-6 in serum of patients with polymyalgia rheumatica and giant cell arteritis. Br J Rheumatol.</li>
					<li>1990;29(6):456-458. doi:10.1093/rheumatology/29.6.456</li>
					<li>Favalli E. Understanding the role of interleukin-6 (IL-6) in the joint and beyond: a comprehensive review of IL-6 inhibition for the management of rheumatoid arthritis. Rheumatol Ther. 2020;7(3):473-516. doi:10.1007/s40744-020-00219-2</li>
					<li>Floris &nbsp;A, &nbsp;Piga &nbsp;M, &nbsp;Cauli &nbsp;A, &nbsp;Salvarani &nbsp;C, &nbsp;Mathieu &nbsp;A. &nbsp;Polymyalgia &nbsp;rheumatica: &nbsp;an &nbsp;autoinflammatory &nbsp;disorder? RMD Open. 2018;4(1):e000694. doi:10.1136/rmdopen-2018-000694</li>
					<li>Gabay C. Interleukin-6 and chronic inflammation. Arthritis Research &amp; Therapy 2006, 8(Suppl 2):S3</li>
					<li>Gabriel SE, Sunku J, Salvarani C, O&rsquo;Fallon WM, Hunder GG. Adverse outcomes of antiinflammatory therapy among patients with polymyalgia rheumatica. Arthritis Rheum. 1997;40(10):1873-1878. doi:10.1002/art.1780401022</li>
					<li>Gonzalez-Gay MA, Matteson EL, Casta&ntilde;eda S. Polymyalgia rheumatica. Lancet. 2017;390(10103):1700-1712. doi:10.1016/S0140- 6736(17)31825-1</li>
					<li>Grossberg AJ, Vichaya EG, Gross PS, Ford BG, Scott KA, Estrada D, et al. Interleukin 6-independent metabolic reprogramming as a driver of cancer-related fatigue. Brain Behav Immun. 2020;88:230-241. doi: 10.1016/j.bbi.2020.05.043</li>
					<li>Grygiel-Gó rniak B, Puszczewicz M. Fatigue and interleukin-6 &ndash; a multi-faceted relationship. Reumatologia. 2015;53(4):207-212. doi:10.5114/reum.2015.53998</li>
					<li>Guggino G, Ferrante A, Macaluso F, Triolo G, Ciccia F. Pathogenesis of polymyalgia rheumatica. Reumatismo. 2018;70(1):10-17. doi:10.4081/reumatismo.2018.1048</li>
					<li>Hysa E, Gotelli E, Sammorı̀ S, Cimmino MA, Paolino S, Pizzorni C, et al. Immune system activation in polymyalgia rheumatica: which balance between autoinflammation and autoimmunity? A systematic review. Autoimmunity Rev. 2022;21(2):102995. doi:10.1016/j.autrev.2021.102995</li>
					<li>Jiemy WF, Zhang A, Boots AMH, Heeringa P, Sandovici M, Diepstra A, et al. Expression of interleukin-6 in synovial tissue of patients with polymyalgia rheumatica. Ann Rheum Dis. 2022;annrheumdis-2022-222873. Online ahead of print. doi:10.1136/ard-2022-222873</li>
					<li>Kappelmann N, Dantzer R, Khandaker GM. Interleukin-6 as potential mediator of long-term neuropsychiatric symptoms of COVID-19. Psychoneuroendocrinology. 2021;131:105295. doi: 10.1016/j.psyneuen.2021.105295</li>
					<li>Kreiner F, Langberg H, Galbo H. Increased muscle interstitial levels of inflammatory cytokines in polymyalgia rheumatica. Arthritis Rheum. 2010;62(12):3768-3775. doi:10.1002/art.27728</li>
					<li>Meliconi R, Pulsatelli L, Uguccioni M, Salvarani C, Macchioni P, Melchiorri C, et al. Leukocyte infiltration in synovial tissue from the shoulder of patients with polymyalgia rheumatica. Quantitative analysis and influence of corticosteroid treatment. Arthritis Rheum. 1996;39(7):1199-1207. doi:10.1002/art.1780390719</li>
					<li>Milchert M, Brzosko M. Diagnosis of polymyalgia rheumatica usually means a favourable outcome for your patient.Indian J Med Res. 2017;145(5):593-600. doi:10.4103/ijmr.IJMR_298_17</li>
					<li>Mori S, Koga Y. Glucocorticoid-resistant polymyalgia rheumatica: pretreatment characteristics and tocilizumab therapy. Clin Rheumatol. 2016;35(5):1367-1375. doi:10.1007/s10067-014-2650-y</li>
					<li>Muller S., Whittle R., Hider S.L., Belcher J., Helliwell T., Morton C., Hughes E., Lawton S.A., Mallen C.D. Longitudinal clusters of pain and stiffness in polymyalgia rheumatica: 2-year results from the PMR Cohort Study. Rheumatology 2019;0:1&ndash;10</li>
					<li>Nesher G, Breuer G. Giant cell arteritis and polymyalgia rheumatica: 2016 update. Rambam Maimonides Med J. 2016;7(4):e0035. doi:10.5041/RMMJ.10262</li>
					<li>Roche NE, Fulbright JW, Wagner AD, Hunder GG, Goronzy JJ, Weyand CM. Correlation of interleukin-6 production and disease activity in polymyalgia rheumatica and giant cell arteritis. Arthritis Rheum. 1993;36(9):1286-1294. doi:10.1002/art.1780360913</li>
					<li>Salvarani C, Barozzi L, Boiardi L, Pipitone N, Bajocchi GL, Macchioni PL, et al. Lumbar interspinous bursitis in active polymyalgia rheumatica. Clin Exp Rheumatol. 2013;31(4):526-531.</li>
					<li>Sebba, A. Pain: A Review of Interleukin-6 and Its Roles in the Pain of Rheumatoid Arthritis. Open Access Rheumatol. 2021;13:31-43. doi: 10.2147/OARRR.S291388</li>
					<li>Ting EY-C, Yang AC, Tsai S-J. Role of interleukin-6 in depressive disorder. Int J Mol Sci. 2020;21(6):2194. doi:10.3390/ijms21062194</li>
					<li>Twohig H, Mitchell C, Mallen C, Adebajo A, Mathers N. &ldquo;I suddenly felt I&rsquo;d aged&rdquo;: a qualitative study of patient experiences of polymyalgia rheumatica (PMR). Patient Educ Couns. 2015;98(5):645-650. doi:10.1016/j.pec.2014.12.013</li>
					<li>Unwin B, Williams CM, Gilliland W. Polymyalgia rheumatica and giant cell arteritis. &nbsp;Am Fam Physician. 2006;74(9):1547-1554.</li>
					<li>Vgontzas AN, Bixler EO, Lin H-M, Prolo P, Trakada G, Chrousos GP. IL-6 and its circadian secretion in humans. Neuroimmunomodulation. 2005;12(3):131-140. doi:10.1159/000084844</li>
					<li>Zakout SA, Clarke LL, Jessop D, Rainer H, Straub RH, Kirwan JR. Circadian variation in plasma IL-6 and the role of modified-release prednisone in polymyalgia rheumatica. Int J Clin Rheumatol. 2014;9(5):431-439. doi:10.2217/IJR.14.29<br />
					&nbsp;</li>
				</ul>
				</>
			}
			{props.slug === "stone" &&
				<>
				<p><strong>PMR Management: Challenges &amp; Opportunities</strong></p>

				<p><strong>ReachMD Announcer:</strong><br />
				You&#39;re listening to ReachMD. This medical industry feature, titled &ldquo;PMR Management: Challenges and Opportunities,&rdquo; is sponsored by Sanofi Genzyme and Regeneron Pharmaceuticals. This speaker was compensated by Sanofi and Regeneron for this podcast. This podcast is for informational purposes only and does not constitute medical advice. As of this publication, except for a delayed-release prednisone, no other treatments mentioned are either indicated or FDA approved for the treatment of PMR. Here is Dr John Stone.</p>

				<p><strong>Dr. Stone:</strong><br />
				I&rsquo;m Dr. John Stone. I&rsquo;m a rheumatologist and a Professor of Medicine in Boston, Massachusetts.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				What options are currently available to manage PMR symptoms?</p>

				<p><strong>Dr. Stone:</strong><br />
				So really, we have very limited, very few management approaches. For the past 70 years or so, the only therapy truly regarded universally as being effective is glucocorticoids.<br />
				One can also use nonsteroidal anti-inflammatory drugs for some of the other musculoskeletal symptoms. In my experience, those drugs don&rsquo;t work nearly as well as prednisone, and in the elderly population, non-steroidals have lots of adverse effects: gastric ulceration, renal insufficiency, hypertension, et cetera, so I tend to avoid those.<br />
				But, because of the desperation to reduce glucocorticoid toxicity, rheumatologists often use methotrexate, often use leflunomides, sometimes use Plaquenil, and a whole host of other things have been tried for polymyalgia rheumatica. But suffice it to say that for 7 decades now, nothing except for glucocorticoids have been widely regarded to be effective.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				What types of patients that present with PMR symptoms are not appropriate for glucocorticoids?</p>

				<p><strong>Dr. Stone:</strong><br />
				So, remember, these patients are typically elderly, so they have many comorbidities. This may include obesity, glucose intolerance, many of them may be on insulin, they may be brittle diabetics, they may have hypertension, they may have cataracts or glaucoma and be at risk for serious eye complications from steroids. Patients who are osteoporotic--the disease affects women 3 times more often than it affects men, and women are at a greater risk of osteoporosis &ndash; not that men can&#39;t get osteoporosis as well. So, bone health and poor bone health is a major relative contraindication to the use of steroids. And any patient who has had difficulty with infectious issues, infections of one type or another, then glucocorticoids lower the threshold for infection. And it&rsquo;s been shown that doses even lower than 5 milligrams a day significantly increase the risk of serious infections leading to hospitalization. So, there&rsquo;s a long list of relative contraindications to prolonged steroid use.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				How do you approach treating PMR patients who are unable to take corticosteroids or who relapse while tapering off steroids?</p>

				<p><strong>Dr. Stone:</strong><br />
				Well, this is the battle that we continually fight, because we really have to treat these patients with glucocorticoids. So, even if they have comorbidities that put them at substantial risk for glucocorticoid toxicity and make steroids relatively contraindicated, we have to use them because patients are utterly miserable without them in the early going. So, we try to minimize the dose. If I have an elderly, osteoporotic woman or a man with a history of neuropsychiatric issues that might make them tolerate glucocorticoids poorly, then I might bite the bullet and start at a lower-than-usual dose. The same is true if a patient is a brittle diabetic. I would perhaps start at a lower dose. And I would try to reduce the prednisone very quickly to attenuate &ndash; mitigate some of the very predictable glucocorticoid side effects.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				What are the current challenges clinicians face when managing PMR today?</p>

				<p><strong>Dr. Stone:</strong><br />
				I think the biggest challenge that physicians still face, really, is managing the glucocorticoid toxicity. Remember, this is a disease that tends to affect middle-aged and elderly individuals with, really, the emphasis on &ldquo;the elderly.&rdquo; So, they very often have multiple comorbidities at the time of their diagnosis. So, this means big challenges when it comes to obesity, diabetes, hypertension, osteoporosis, risk of infection, et cetera.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				What opportunities exist to improve the management of PMR?</p>

				<p><strong>Dr. Stone:</strong><br />
				Well, it begins with early diagnosis. And so, teaching our providers, physicians of other healthcare providers, to be more attuned to the symptoms of PMR &ndash; that&rsquo;s very, very important.<br />
				And then, being wiser about how we use glucocorticoids, paying careful attention to glucocorticoid toxicities and avoiding them, prophylaxing against these toxicities to the extent that they can be prevented, will all help outcomes.</p>

				<p>Ultimately, the management of PMR is going to be improved when we have better medications and one can look around the rheumatic disease space, the inflammatory disease space, and see so many examples where treatment has been improved by the development of better therapies.<br />
				PMR remains a striking exception for a disease that is so common in the United States and in the world. So, ultimately, it&rsquo;s really going to be a better understanding of the pathophysiology of the disease.</p>

				<p><strong>ReachMD Announcer:</strong><br />
				This program was sponsored by Sanofi Genzyme and Regeneron Pharmaceuticals. If you missed any part of this discussion, visit ReachMD.com/industryfeature. This is ReachMD. Be Part of the Knowledge.</p>

				<p><strong>References</strong></p>

				<ol>
					<li>Algeria G, Boukhlal S, Cornec D, Devauchelle-Pensec V. The pathophysiology of polymyalgia rheumatica, small pieces of a big puzzle. Autoimmun Rev. 2020; 19(11):6. doi: 10.1016/j.autrev.2020.102670</li>
					<li>Bin-Mahmoud S, Nelson E, Padniewski J, Nasr R. Polymyalgia rheumatica: an updated review.Cleve Clin J Med. 2020; 87(9):553. doi:10.3949/ccjm.87a.20008</li>
					<li>Dejaco C, Singh YP, Perel P, et al.. 2015 Recommendations for the management of polymyalgia rheumatica: a European League Against Rheumatism/American College of Rheumatology collaborative initiative. Ann Rheum Dis. 2015;74:1799-1807. doi:10.1136/annrheumdis-2015-207492</li>
					<li>Emamifar A, Gildberg-Mortensen R, Just SA, Lomborg N, Andreasen RA, Jensen Hansen IM. Level of adherence to prophylactic osteoporosis medication amongst patients with polymyalgia rheumatica and giant cell arteritis: a cross- sectional study. Int J Rheumatol. 2015;volume:1-4. doi:10.1155/2015/783709</li>
					<li>Gabriel SE, Sunku J, Salvarani C, O&rsquo;Fallon WM, Hunder GG. Adverse outcomes of antiinflammatory therapy among patients with polymyalgia rheumatica. Arthritis Rheumatol. 1997;40(10):1873-1878.</li>
					<li>Harirforoosh S. Fakhreddin J. Renal adverse effects of nonsteroidal anti-inflammatory drugs.Expert Opin Drug Saf. 2009; 8(6):669-681.</li>
					<li>Hodgens A, Sharman T. Corticosteroids. StatPearls. Updated July 26, 2022. Accessed October 11, 2022 https://www.ncbi.nlm.nih.gov/books/NBK554612/</li>
					<li>Koster MJ, Warrington KJ. Latest advances in the diagnosis and treatment of polymyalgia rheumatica. Pract Pain Manag. 2017;15(9):14. https://www.practicalpainmanagement.com/pain/myofascial/inflammatory-arthritis/latest- advances-diagnosis-treatment-polymyalgia-rheumatica</li>
					<li>Matteson EL, Dejaco C. Polymyalgia rheumatica. Ann Intern Med. 2017;166(9):ITC65-ITC80. doi:10.7326/AITC201705020</li>
					<li>Milchert M, Brzosko M. Diagnosis of polymyalgia rheumatica usually means a favourable outcome for your patient. Indian J Med Res. 2017;145(5):593-600. doi:10.4103/ijmr.IJMR_298_17</li>
					<li>Toussirot E, Alexis R&eacute;gent A. Interleukin-6: a promising target for the treatment of polymyalgia rheumatica or giant cell arteritis? RMD Open. 2016;2(2):1-6e000305. doi:10.1136/rmdopen-2016-000305</li>
					<li>Youssef J, Novosad SA, Winthrop KL. Infection risk and safety of corticosteroid use. Rheum Dis Clin North Am. 2016;42(1):157-176. doi:10.1016/j.rdc.2015.08.004<br />
					&nbsp;</li>
				</ol>
			</>
			}
			{props.slug === "rheum" &&
				<>
				<p><strong>Reading the Rheum: Let&#39;s Talk About PMR Management &ndash; Transcript</strong></p>

				<p><strong>Announcer:</strong><br />
				This medical industry feature, titled &ldquo;Reading the Rheum: Let&#39;s Talk About PMR Management,&quot; is brought to you by Sanofi and Regeneron. This program is intended for healthcare professionals. Here&rsquo;s your host, Dr Jennifer Caudle.</p>

				<p><strong>Dr Caudle:</strong><br />
				What clinical approaches and considerations are needed to determine the diagnosis for patients who present with polymyalgia rheumatica, or PMR, for short? That question will be the focus of our discussion today.</p>

				<p>I&rsquo;m your host, Dr Jennifer Caudle, and joining me to discuss the pain and presentation of PMR are Dr Anisha Dua and Ms Amanda Mixon. Dr Dua is an Associate Professor of Medicine in the Division of Rheumatology. Dr Dua, thank you so much for being here today.</p>

				<p><strong>Dr Dua:</strong><br />
				It&#39;s a pleasure to be here.</p>

				<p><strong>Dr Caudle:</strong><br />
				And Ms Mixon is a physician assistant specializing in rheumatology. Ms Mixon, it&rsquo;s great to have you with us.</p>

				<p><strong>Ms Mixon:</strong><br />
				Thank you for inviting us.</p>

				<p><strong>Dr Caudle:</strong><br />
				Of course. Well, we&rsquo;re happy that you&rsquo;re here. So, you know, let&rsquo;s begin with some background. Dr Dua, how prevalent is PMR, and what types of patients are most commonly affected?</p>

				<p><strong>Dr Dua:&nbsp;</strong><br />
				PMR is a very common inflammatory rheumatic disease, much more common than people think. Just to give you an idea, it&#39;s even more common than colorectal cancer. And the patients who suffer most often from PMR are typically 50 years or older. And it&#39;s something we see much more commonly in women.</p>

				<p><strong>Dr Caudle:</strong><br />
				And now turning to you, Ms Mixon, what symptoms do PMR patients most commonly experience, and how do they compare to other rheumatic conditions?</p>

				<p><strong>Ms Mixon:</strong><br />
				PMR is characterized by pain and stiffness in the neck, shoulders, and pelvic girdles. About half of PMR patients experience weight loss, fatigue, and/or low-grade fever that can last for months. PMR is often described as a flu that doesn&rsquo;t go away. Patients often struggle to sleep because it hurts when they move in bed, and sometimes they have a hard time getting out of bed because the pain is usually more intense in the morning.</p>

				<p>There is also an aspect of losing independence, like not being able to comb their hair. The pain that patients experience is quick to onset and can be debilitating, negatively affecting their quality of life.</p>

				<p><strong>Dr Caudle:</strong><br />
				Thank you for that. Now let&rsquo;s switch gears and talk about making a diagnosis. Dr Dua, how is PMR typically diagnosed, and are there any barriers that may prevent diagnosis?</p>

				<p><strong>Dr Dua:</strong><br />
				Diagnosis can be tricky, since there is no test or biomarker that&#39;s specific for PMR. It can also mimic other diseases. So diagnosis is based on clinical history with some lab tests to exclude other illnesses. But I always say that getting a sense of the clinical history is the most important diagnostic test for PMR. Some blood tests can be helpful, like the sed rate and the C-reactive protein. And you can also exclude some mimics of PMR by checking things like rheumatoid factor, or anti-CCP antibodies. But typically when you&#39;re diagnosing PMR, clinicians are really working to rule out rheumatoid arthritis, inflammatory myopathy, infections, or cancers, and some forms of metabolic disease can all mimic PMR. So in many cases, we need to see a patient&#39;s response to treatment in order to confirm a diagnosis.</p>

				<p><strong>Dr Caudle:</strong><br />
				You know, that seems like a very different method compared to diagnosing other diseases. Can you explain further about how a patient&rsquo;s response to initial treatment may help confirm the presence of PMR?</p>

				<p><strong>Ms Mixon:</strong><br />
				Yeah. Patients are typically treated first with moderate doses of steroids, and a rapid response, usually within days, supports a diagnosis of PMR. In general, if you think a patient has PMR, we recommend referring them to a rheumatologist, and if a patient&rsquo;s symptoms don&rsquo;t resolve within days of steroid treatment, then the patient needs to be seen immediately by a specialist for further diagnostic workup.</p>

				<p>Also, if PMR is suspected, it&rsquo;s important to check for symptoms of giant cell arteritis, or GCA for short, which is a systemic vasculitis affecting the large arteries. PMR and GCA can appear either simultaneously or consecutively, but 40-50% of GCA patients have PMR manifestations. GCA is a medical emergency, and if left untreated it can cause serious consequences, such as blindness, stroke, and aortic aneurism. So it&rsquo;s important to screen all patients with suspected PMR for GCA as well.</p>

				<p>I&rsquo;d also like to note that if a patient has GCA symptoms, it is recommended that they be referred to a rheumatologist immediately.</p>

				<p><strong>Dr Caudle:</strong><br />
				Now, Ms Mixon, you mentioned steroids are usually first-line treatment. Dr Dua, can you tell us why and how they&rsquo;re typically used to treat PMR?</p>

				<p><strong>Dr Dua:</strong><br />
				Yeah, so steroids are typically used because of the inflammatory nature of PMR. But the regimen and then the schedule can really vary between the patients. So usually, patients are started on glucocorticoids with a dose of around 12.5 to 25 milligrams per day. And we use that for about 4 weeks. Usually, patients feel dramatically better within the first few days of treatment. And then once we have stable symptoms, then we&#39;ll start the tapering process to try to get them off of steroids. However, it needs to be individualized to the patient. And it&#39;s recommended that the dose be reduced gradually, because if it&#39;s done too fast, then the patient&#39;s symptoms can come back.</p>

				<p><strong>Dr Caudle:</strong><br />
				And as a quick follow-up to that, Ms Mixon, how important is tapering off steroids?</p>

				<p><strong>Ms Mixon:</strong><br />
				It&rsquo;s very important. Glucocorticoids are useful in the initial stages post-diagnosis, but it&rsquo;s important not to use them for too long. About a quarter of PMR patients are still on steroids at various dosages, five years after treatment initiation, which is not ideal.</p>

				<p>The main reason we want to avoid long-term use of steroids is because of their side effects, even if continued at relatively low doses. Our goal is to make sure that the symptoms are controlled while also monitoring steroid toxicity. For example, we recommend monitoring patients&rsquo; blood pressure, vision changes and weight gain, and do regular osteoporosis screening and bone density measurements, just to name a few.</p>

				<p><strong>Dr Caudle:</strong><br />
				And Dr Dua, are there any patients with PMR who shouldn&rsquo;t receive steroids?</p>

				<p><strong>Dr Dua:</strong><br />
				We worry about conditions like hyperglycemia diabetes, glaucoma, people who have joint infections are prone to multiple infections, uncontrolled hypertension, all of those are things that make us worry a little bit about the use of glucocorticoids. We still may need to use them, but we do so with caution. And so, of course, if a patient has one of these conditions, or we&#39;re unable to fully taper them off of the steroids, then they might benefit from using a different steroid-sparing agent. So sometimes we&#39;ll use things like methotrexate or other immunosuppressives.</p>

				<p>But methotrexate can also be associated with other adverse events that require regular blood monitoring. I mean, as rheumatologists, we&#39;ve used methotrexate to reduce glucocorticoid toxicity. And we&#39;ve used other drugs such as leflunomide, hydroxychloroquine, and a variety of other treatments. But really, for seven decades now, nothing except glucocorticoids has been widely regarded as effective.&nbsp;</p>

				<p><strong>Dr Caudle:</strong><br />
				As we come to a close, I&rsquo;d like to thank our guests, Dr Anisha Dua and Ms Amanda Mixon, for joining us to explore diagnostic and therapeutic considerations for polymyalgia rheumatica. Dr Dua and Ms Mixon, it was great speaking with you both today.</p>

				<p><strong>Dr Dua:&nbsp;</strong><br />
				Thank you very much. I really enjoyed our conversation.</p>

				<p><strong>Ms Mixon:</strong><br />
				Thank you for having me. It was my pleasure.</p>

				<p><strong>Dr Caudle:</strong><br />
				I&rsquo;m your host, Dr Jennifer Caudle.</p>

				<p><strong>Announcer:</strong><br />
				This program was brought to you by Sanofi and Regeneron.&nbsp;</p>

				<p><strong>References</strong></p>

				<ol>
					<li>Algeria G, Boukhlal S, Cornec D, Devauchelle-Pensec V. The pathophysiology of polymyalgia rheumatica, small pieces of a big puzzle. Autoimmun Rev. 2020;19(11):6. doi:10.1016/j.autrev.2020.102670</li>
					<li>Bartoloni E, Pucci G, Alunno A, Gerli R, Shchillaci G. Chapter 9: Polymyalgia Rheumatica. In: Nussinovitch U, ed. The Heart in Rheumatic, Autoimmune and Inflammatory Diseases. 1st edition. Elsevier; 2017:213-231.</li>
					<li>Bin-Mahmoud S, Nelson E, Padniewski J, Nasr R. Polymyalgia rheumatica: an updated review. Cleve Clin J Med. 2020;87(9):553. doi:10.3949/ccjm.87a.20008</li>
					<li>Camellino D, Duftner C, Dejaco C. New insights into the role of imaging in polymyalgia rheumatica. Rheumatology (Oxford). 2021;60(3):1016-1033. doi:10.1093/rheumatology/keaa646</li>
					<li>Dejaco C, Singh YP, Perel P. 2015 Recommendations for the management of polymyalgia rheumatica: a European League Against Rheumatism/American College of Rheumatology collaborative initiative. Ann Rheum Dis. 2015;74:1799-1807. doi:10.1136/annrheumdis-2015-207492</li>
					<li>Dejaco C, Brouwer E, Mason JC, Buttgereit F, Matteson EL, Dasgupta B. Giant cell arteritis and polymyalgia rheumatica: current challenges and opportunities. Nature Reviews Rheumatology. 2017;13(10):578-592. doi:10.1038/nrrheum.2017.142</li>
					<li>Floris A, Piga M, Cauli A, Salvarani C, Mathieu A. Polymyalgia rheumatica: an autoinflammatory disorder? RMD Open. 2018;4(1):e000694. doi:10.1136/rmdopen-2018-000694</li>
					<li>Floris A, Piga M, Chessa E, et al. Long-term glucocorticoid treatment and high relapse rate remain unresolved issues in the real-life management of polymyalgia rheumatica: a systematic literature review and meta-analysis. Clinical Rheumatology. 2022;41(1):19-31. doi:10.1007/s10067-021-05819-z</li>
					<li>Gonzalez-Gay MA, Matteson EL, Casta&ntilde;eda S. Polymyalgia rheumatica. Lancet. 2017;390(10103):1700-1712. doi:10.1016/S0140-6736(17)31825-1</li>
					<li>Hodgens A, Sharman T. Corticosteroids. StatPearls. Updated July 26, 2022. Accessed October 11, 2022 https://www.ncbi.nlm.nih.gov/books/NBK554612/</li>
					<li>Korkmaz C, Yıldız P. Giant cell arteritis, polymyalgia rheumatica, and late-onset rheumatoid arthritis: Can they be components of a single disease process in elderly patients? European Journal of Rheumatology. 2017;4(2):157-160. doi:10.5152/eurjrheum.2016.039</li>
					<li>Lopez-Hoyos M. Clinical utility of anti-CCP antibodies in the differential diagnosis of elderly-onset rheumatoid arthritis and polymyalgia rheumatica. Rheumatology. 2004;43(5):655-657. doi:10.1093/rheumatology/keh143</li>
					<li>Matteson EL, Dejaco C. Polymyalgia rheumatica. Ann Intern Med. 2017;166(9):ITC65-ITC80. doi:10.7326/AITC201705020</li>
					<li>Milchert M, Brzosko M. Diagnosis of polymyalgia rheumatica usually means a favourable outcome for your patient. Indian J Med Res. 2017;145(5):593-600. doi:10.4103/ijmr.IJMR_298_17</li>
					<li>National Cancer Institute. Cancer of the Colon and Rectum - Cancer Stat Facts. SEER. Published 2018. https://seer.cancer.gov/statfacts/html/colorect.html</li>
					<li>Prior JA, Ranjbar H, Belcher J, et al. Diagnostic delay for giant cell arteritis &ndash; a systematic review and meta-analysis. BMC Med. 2017;15(1):120. doi:10.1186/s12916-017-0871-z</li>
					<li>Raheel S, Shbeeb I, Crowson CS, Matteson EL. Epidemiology of Polymyalgia Rheumatica 2000&ndash;2014 and Examination of Incidence and Survival Trends Over 45 Years: A Population‐Based Study. Arthritis Care &amp; Research. 2017;69(8):1282-1285. doi:10.1002/acr.23132</li>
				</ol>

				<p>MAT-US-2209555-V1.0-03/2023</p>
				</>
			}
		</div>
	  </div>
  )
}

export default AudioTranscriptTest
